import { Component, NgZone, OnDestroy, OnInit, Signal, signal } from '@angular/core';
import {
  Command,
  CommandStore, MenuItem,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore, WithMenuItems, WithStaticTitle, WithTitle
} from '@softline/application';
import {Store} from '@softline/core';
import {
  SOFTLINE_DEFINITION_ARBEITSBERICHT_EDIT,
  SOFTLINE_DEFINITION_ARBEITSBERICHT_INPUT,
  SOFTLINE_DEFINITION_ARBEITSBERICHT_LIST,
  SOFTLINE_DEFINITION_ARBEITSBERICHT_QUERY,
} from '../arbeitsbericht.api';
import {
  SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
  SOFTLINE_GEWERBE_ARBEITSBERICHT_SELECTED_PERSONS
} from '../arbeitsbericht.shared';
import {TimeReportStore} from '../store/time-report.store';
import {DefinitionStore, SOFTLINE_FEATURE_DEFINITIONS,} from '@softline/dynamic';
import {Router, RouterModule} from '@angular/router';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'soft-time-report',
  standalone: true,
  templateUrl: './time-report.component.html',
  styleUrls: ['./time-report.component.scss'],
  imports: [RouterModule],
  providers: [
    {
      provide: SOFTLINE_GEWERBE_ARBEITSBERICHT_SELECTED_PERSONS,
      useFactory: () => signal([])
    }
  ]
})
export class TimeReportComponent
  extends
    WithMenuItems(
    WithStaticTitle('#TIME_REPORT.TITLE'
    ))
  implements OnInit, OnDestroy {

  override menuItems: Signal<MenuItem[]> = signal([
    {
      name: 'arbeitsberichtOverview',
      type: 'route',
      outlet: 'nav',
      priority: 1,
      icon: 'fa-regular fa-calendar-check',
      title: '#TIME_REPORT.MENU.LIST',
      routerLink: ['/arbeitsbericht'],
    },
    {
      name: 'arbeitsberichtQuery',
      type: 'route',
      outlet: 'nav',
      priority: 2,
      icon: 'fa-regular fa-magnifying-glass',
      title: '#TIME_REPORT.MENU.QUERY',
      routerLink: ['/arbeitsbericht', 'query'],
    },
  ])

  constructor(
    private store: Store,
    protected router: Router
  ) { super(); }

  override async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.store.commit(
      SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
      TimeReportStore.mutations.clear
    );
    await this.store.dispatch(
      SOFTLINE_FEATURE_DEFINITIONS,
      DefinitionStore.actions.load,
      { name: SOFTLINE_DEFINITION_ARBEITSBERICHT_QUERY }
    );
    await this.store.dispatch(
      SOFTLINE_FEATURE_DEFINITIONS,
      DefinitionStore.actions.load,
      { name: SOFTLINE_DEFINITION_ARBEITSBERICHT_LIST }
    );
    await this.store.dispatch(
      SOFTLINE_FEATURE_DEFINITIONS,
      DefinitionStore.actions.load,
      { name: SOFTLINE_DEFINITION_ARBEITSBERICHT_EDIT }
    );
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
