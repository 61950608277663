import { RemoteCollectionStore } from '@softline/core';
import { Objekt } from './models/objekt.model';
import {inject, InjectionToken, signal, Type, WritableSignal} from '@angular/core';
import { TimeReportItemComponent } from './components/time-report-item/time-report-item.component';
import {Employee} from './models/employee.model';

export const SOFTLINE_FEATURE_TIME_REPORT_RECENT_OBJECT =
  'timeReportRecentObject';
export const SOFTLINE_FEATURE_TIME_REPORT_CORE_DATA = 'timeReportCoreData';
export const SOFTLINE_FEATURE_TIME_REPORT_PARTY_FAVORITES =
  'timeReportPartyFavorites';
export const SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT = 'timeReportTimeReport';

export const RecentObjectsStore = RemoteCollectionStore.create<Objekt>();

export const SOFTLINE_GEWERBE_ARBEITSBERICHT_SELECTED_PERSONS = new InjectionToken<WritableSignal<Employee[]>>(
  'SOFTLINE_GEWERBE_ARBEITSBERICHT_SELECTED_PERSONS',
  { factory: () => signal<Employee[]>([]) }
);

export function injectSelectedPersons(): WritableSignal<Employee[]> {
  return inject(SOFTLINE_GEWERBE_ARBEITSBERICHT_SELECTED_PERSONS)
}

export const SOFTLINE_COMPONENT_TIME_REPORT_ITEM = new InjectionToken<Type<TimeReportItemComponent>>('SOFTLINE_COMPONENT_TIME_REPORT_ITEM', {
  providedIn: 'root',
  factory: () => TimeReportItemComponent
});
