import { importProvidersFrom, Optional } from '@angular/core';
import { Routes } from '@angular/router';
import { AuthenticationGuard, AuthorizationGuard } from '@softline/auth';
import {
  ConnectionHttpService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SOFTLINE_STORE_TEMPLATE_SERVICE,
  SoftlineEntityService,
  SoftlineTemplateService,
  StoreModule,
} from '@softline/core';
import {
  RecentObjectsStore,
  SOFTLINE_FEATURE_TIME_REPORT_CORE_DATA,
  SOFTLINE_FEATURE_TIME_REPORT_PARTY_FAVORITES,
  SOFTLINE_FEATURE_TIME_REPORT_RECENT_OBJECT,
  SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
} from './arbeitsbericht.shared';
import { PartyFavoritesStore } from './store/party-favorites.store';
import {
  COMPANY_DATA_RESOURCE_PATH,
  OBJECTS_RESOURCE_PATH,
  PARTY_FAVORITES_RESOURCE_PATH,
  SOFTLINE_API_TIME_REPORT,
} from './arbeitsbericht.api';
import { PartyFavoritesService } from './services/party-favorites.service';
import { CoreDataStore } from './store/core-data.store';
import { CoreDataService } from './services/core-data.service';
import { TimeReportStore } from './store/time-report.store';
import { TimeReportResourceService } from './services/time-report.service';
import {
  ArbeitsberichtConfig,
  SOFTLINE_CONFIG_ARBEITSBERICHT,
} from './arbeitsbericht.config';
import { ScanOrderService } from './services/scan-order.service';
import { SOFTLINE_PERMISSION_TIME_REPORT } from './arbeitsbericht.permissions';
import { askUnsaved } from '@softapps/core';

export const arbeitsberichtRoutes: Routes = [
  {
    path: 'arbeitsbericht',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./components/time-report.component').then(
            (o) => o.TimeReportComponent
          ),
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./pages/overview/overview.page').then(
                (o) => o.OverviewPage
              ),
          },
          {
            path: 'create',
            loadComponent: () =>
              import('./pages/create/create.page').then((o) => o.CreatePage),
            canDeactivate: [askUnsaved],
          },
          {
            path: 'query',
            loadComponent: () =>
              import('./pages/query/query.component').then(
                (o) => o.QueryComponent
              ),
          },
          {
            path: 'result',
            loadComponent: () =>
              import('./pages/query-result/query-result.component').then(
                (o) => o.QueryResultComponent
              ),
          },
          {
            path: ':id/edit',
            loadComponent: () =>
              import('./pages/edit/edit.component').then(
                (o) => o.EditComponent
              ),
          },
          { path: '**', redirectTo: '' },
        ],
      },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: { permission: SOFTLINE_PERMISSION_TIME_REPORT },
    providers: [
      {
        provide: ScanOrderService,
        useFactory: (service: ConnectionHttpService) =>
          new ScanOrderService(service),
        deps: [SOFTLINE_SERVICE_HTTP],
      },
      importProvidersFrom(
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_TIME_REPORT_PARTY_FAVORITES,
          feature: PartyFavoritesStore.feature,
          providers: [
            {
              provide: SOFTLINE_STORE_ENTITY_SERVICE,
              useFactory: (service: ConnectionHttpService) =>
                new SoftlineEntityService(
                  service,
                  PARTY_FAVORITES_RESOURCE_PATH
                ),
              deps: [SOFTLINE_SERVICE_HTTP],
            },
            {
              provide: PartyFavoritesService,
              useFactory: (service: ConnectionHttpService) =>
                new PartyFavoritesService(service),
              deps: [SOFTLINE_SERVICE_HTTP],
            },
          ],
        }),
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_TIME_REPORT_RECENT_OBJECT,
          feature: RecentObjectsStore.feature,
          providers: [
            {
              provide: SOFTLINE_STORE_ENTITY_SERVICE,
              useFactory: (service: ConnectionHttpService) =>
                new SoftlineEntityService(service, OBJECTS_RESOURCE_PATH),
              deps: [SOFTLINE_SERVICE_HTTP],
            },
          ],
        }),
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_TIME_REPORT_CORE_DATA,
          feature: CoreDataStore.feature,
          providers: [
            {
              provide: SOFTLINE_STORE_ENTITY_SERVICE,
              useFactory: (service: ConnectionHttpService) =>
                new SoftlineEntityService(service, COMPANY_DATA_RESOURCE_PATH),
              deps: [SOFTLINE_SERVICE_HTTP],
            },
            {
              provide: CoreDataService,
              useFactory: (service: ConnectionHttpService) =>
                new CoreDataService(service),
              deps: [SOFTLINE_SERVICE_HTTP],
            },
          ],
        }),
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT,
          feature: TimeReportStore.feature,
          providers: [
            {
              provide: SOFTLINE_STORE_ENTITY_SERVICE,
              useFactory: (service: ConnectionHttpService) =>
                new SoftlineEntityService(service, SOFTLINE_API_TIME_REPORT),
              deps: [SOFTLINE_SERVICE_HTTP],
            },
            {
              provide: SOFTLINE_STORE_TEMPLATE_SERVICE,
              useFactory: (service: ConnectionHttpService) =>
                new SoftlineTemplateService(service, SOFTLINE_API_TIME_REPORT),
              deps: [SOFTLINE_SERVICE_HTTP],
            },
            {
              provide: TimeReportResourceService,
              useFactory: (
                service: ConnectionHttpService,
                config: ArbeitsberichtConfig
              ) => new TimeReportResourceService(config, service),
              deps: [
                ConnectionHttpService,
                [new Optional(), SOFTLINE_CONFIG_ARBEITSBERICHT],
              ],
            },
            {
              provide: ScanOrderService,
              useExisting: ScanOrderService
            },
          ],
        })
      ),
    ],
  },
];
