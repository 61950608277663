import {Component, OnInit} from '@angular/core';
import {CartItem} from '../../data/cart-item';
import {RequestError, Store} from '@softline/core';
import {MessageBarStore, Modal, SOFTLINE_FEATURE_MESSAGE_BAR, UiCoreModule} from '@softline/ui-core';
import {BehaviorSubject} from 'rxjs';
import {SOFTLINE_FEATURE_SHOP_CART} from '../../shop.shared';
import {CartStore} from '../../store/cart.store';
import {showRequestErrors} from '@softline/application';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'lib-edit-cart-item-dialog',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './edit-cart-item-dialog.component.html',
  styleUrls: ['./edit-cart-item-dialog.component.scss']
})
export class EditCartItemDialogComponent implements OnInit, Modal<any> {

  close!: (result: any) => void;

  quantity = 1;
  item: CartItem | null = null;

  readonly isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(private store: Store) { }

  ngOnInit(): void {}

  registerCloseHandler(handler: (result: any) => void): void {
    this.close = handler;
  }

  async onSubmit(amount: number): Promise<void> {
    await this.update(amount);
  }

  private async update(amount: number): Promise<void> {
    if (!this.item)
      return;

    const updatedItem = { ...this.item, amount };
    const update = {
      id: this.item?.id,
      item: updatedItem,
    };

    try {
      this.isLoading$.next(true);
      await this.store.dispatch(SOFTLINE_FEATURE_SHOP_CART, CartStore.actions.update, { item: update });
      this.isLoading$.next(false);
      await this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore.actions.success, {
        title: 'Artikel geändert',
        message: 'Der Artikel wurde erfolgreich geändert'
      });
      this.close(null);
    } catch (e) {
      if (e instanceof RequestError) {
        await this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore.actions.error, {
          title: '#SHOP.SHARED.ERRORS.TITLE',
          message: '#SHOP.SHARED.ERRORS.EDIT_CART_ITEM',
          duration: 5000,
          container: 'EDIT_CART_DIALOG_MESSAGE_BAR_CONTAINER'
        });
      } else {
        showRequestErrors(this.store, e);
      }
    }
  }
}
