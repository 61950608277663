 <div [softMeta]="meta()" [softValue]="value()" class="soft-grid w-full !gap-y-0">
   <div class="flex items-center justify-between col-span-6">
     <soft-meta-field name="date" class="soft-overline"></soft-meta-field>

     <div class="soft-overline ml-2 text-right">
       <soft-meta-field name="from"></soft-meta-field> - <soft-meta-field name="to"></soft-meta-field> Uhr
     </div>

   </div>
    <soft-meta-field name="object" class="text-base font-semibold col-span-5"></soft-meta-field>
    <soft-meta-field name="zeit" class="text-sm text-light col-span-1 text-right"></soft-meta-field>
    <soft-meta-field name="customer" class="text-light text-sm col-span-3"></soft-meta-field>
</div>

